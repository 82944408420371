"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinimapManager = void 0;
var utils_1 = require("../../../utils");
var MinimapManager = (function () {
    function MinimapManager(state, eventBus, theme) {
        var _this = this;
        this.state = state;
        this.eventBus = eventBus;
        this.theme = theme;
        this.minimapLeftMouseClick = function () {
            _this.state.uiState.draggingMinimap = true;
        };
        this.minimapMouseMove = function (_a) {
            var _b = _a.position, x = _b.x, y = _b.y;
            if (_this.state.uiState.draggingMinimap) {
                _this.performPanning({ x: x, y: y });
            }
            _this.state.hoverMinimap = true;
            _this.state.hover.node = undefined;
            _this.eventBus.publish("RenderRequested");
        };
        this.worldMouseMove = function () {
            if (_this.state.hoverMinimap) {
                _this.state.hoverMinimap = false;
                _this.eventBus.publish("RenderRequested");
            }
        };
        this.eventBus.subscribe("MinimapMouseMove", this.minimapMouseMove);
        this.eventBus.subscribe("MinimapLeftMouseClick", this.minimapLeftMouseClick);
        this.eventBus.subscribe("WorldMouseMove", this.worldMouseMove);
    }
    MinimapManager.prototype.performPanning = function (coords) {
        var boundingBoxViewport = utils_1.MinimapUtils.getBoundingBoxViewport({
            x: this.state.uiState.panX,
            y: this.state.uiState.panY,
        }, this.state.uiState.scale, this.state.uiState.areaSize);
        var miniMapBoundaries = utils_1.MinimapUtils.getMiniMapBoundaries(boundingBoxViewport, this.state.uiState.areaSize);
        var worldCoords = utils_1.MinimapUtils.mapToWorldPoint(coords, miniMapBoundaries, this.theme.minimap.size);
        var newPanValue = {
            x: -worldCoords.x +
                this.state.uiState.areaSize.width / 2 / this.state.uiState.scale,
            y: -worldCoords.y +
                this.state.uiState.areaSize.height / 2 / this.state.uiState.scale,
        };
        var newViewport = utils_1.MinimapUtils.getBoundingBoxViewport(newPanValue, this.state.uiState.scale, this.state.uiState.areaSize);
        if (newViewport.left < miniMapBoundaries.left ||
            newViewport.right > miniMapBoundaries.left + miniMapBoundaries.width) {
            newPanValue.x = this.state.uiState.panX;
        }
        if (newViewport.top < miniMapBoundaries.top ||
            newViewport.bottom > miniMapBoundaries.top + miniMapBoundaries.height) {
            newPanValue.y = this.state.uiState.panY;
        }
        this.state.uiState.panX = newPanValue.x;
        this.state.uiState.panY = newPanValue.y;
        this.eventBus.publish("RenderRequested");
    };
    return MinimapManager;
}());
exports.MinimapManager = MinimapManager;
