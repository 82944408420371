"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RectanglePacker = void 0;
var packer_1 = require("./packer");
var RectanglePacker = (function () {
    function RectanglePacker() {
    }
    RectanglePacker.pack = function (blocks, theme) {
        var boxes = (0, packer_1.packBoxes)(blocks.map(function (b, index) {
            return ({
                dimensions: [
                    b.width + theme.graph.spacing.x,
                    b.height + theme.graph.spacing.y,
                ],
                index: index,
            });
        }));
        boxes.forEach(function (box) {
            var block = blocks[box.index];
            var newCenter = {
                x: box.box.position[0] + block.width / 2.0,
                y: box.box.position[1] + block.height / 2.0,
            };
            block.nodes.forEach(function (node) {
                var relativePosition = {
                    x: node.x - block.center.x,
                    y: node.y - block.center.y,
                };
                (node.x = newCenter.x + relativePosition.x),
                    (node.y = newCenter.y + relativePosition.y);
            });
            block.center = __assign({}, newCenter);
        });
    };
    return RectanglePacker;
}());
exports.RectanglePacker = RectanglePacker;
