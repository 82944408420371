"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HoverManager = void 0;
var HoverManager = (function () {
    function HoverManager(state, eventBus) {
        var _this = this;
        this.state = state;
        this.eventBus = eventBus;
        this.hover = function (_a) {
            var position = _a.position;
            var node = _this.state.trees.node.pick(position);
            if (!node) {
                if (_this.state.hover.node) {
                    _this.state.hover = {};
                    _this.eventBus.publish("RenderRequested");
                    return;
                }
                return;
            }
            var distance = {
                x: position.x - node.x,
                y: position.y - node.y,
            };
            var type = distance.y < 0 ? true : undefined;
            if (_this.state.hover.node !== node || _this.state.hover.type !== type) {
                _this.state.hover = { node: node, type: type };
                _this.eventBus.publish("RenderRequested");
            }
        };
        this.eventBus.subscribe("WorldMouseOverMove", this.hover);
        this.eventBus.subscribe("PickRequested", this.hover);
    }
    return HoverManager;
}());
exports.HoverManager = HoverManager;
