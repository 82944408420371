"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuadTree = exports.Region = void 0;
var Region_1 = require("./Region");
Object.defineProperty(exports, "Region", { enumerable: true, get: function () { return Region_1.Region; } });
var QuadTree = (function () {
    function QuadTree(bb, capacity, maxDepth, depth) {
        var _this = this;
        if (bb === void 0) { bb = new Region_1.Region(); }
        if (capacity === void 0) { capacity = 50; }
        if (maxDepth === void 0) { maxDepth = 10; }
        if (depth === void 0) { depth = 0; }
        this.bb = bb;
        this.capacity = capacity;
        this.maxDepth = maxDepth;
        this.depth = depth;
        this.objects = [];
        this.insert = function (node) {
            if (!_this.bb.intersect(node.bb)) {
                return false;
            }
            if ((_this.objects.length < _this.capacity && !_this.sides) ||
                _this.depth === _this.maxDepth) {
                _this.objects.push(node);
                return true;
            }
            if (!_this.sides) {
                _this.subdivide();
            }
            var insertedNode = false;
            if (!_this.sides) {
                throw new Error('Cannot subdivide sides');
            }
            if (_this.sides.nw.insert(node))
                insertedNode = true;
            if (_this.sides.ne.insert(node))
                insertedNode = true;
            if (_this.sides.sw.insert(node))
                insertedNode = true;
            if (_this.sides.se.insert(node))
                insertedNode = true;
            return insertedNode;
        };
        this.delete = function (data, e) {
            var tree = _this.pickTree(__assign({}, e));
            var index = 0;
            if (!tree) {
                return;
            }
            for (var _i = 0, _a = tree.objects; _i < _a.length; _i++) {
                var o = _a[_i];
                if (o.data === data)
                    tree.objects.splice(index, 1)[0].data;
                index++;
            }
        };
        this.update = function (data, e, bb) {
            _this.delete(data, e);
            _this.insert({
                data: data,
                bb: bb,
            });
        };
        this.subdivide = function () {
            var c = _this.bb.center();
            _this.sides = {
                nw: new QuadTree(new Region_1.Region(_this.bb.min, c), _this.capacity, _this.maxDepth, _this.depth + 1),
                ne: new QuadTree(new Region_1.Region({
                    x: c.x,
                    y: _this.bb.min.y,
                }, {
                    x: _this.bb.max.x,
                    y: c.y,
                }), _this.capacity, _this.maxDepth, _this.depth + 1),
                sw: new QuadTree(new Region_1.Region({
                    x: _this.bb.min.x,
                    y: c.y,
                }, {
                    x: c.x,
                    y: _this.bb.max.y,
                }), _this.capacity, _this.maxDepth, _this.depth + 1),
                se: new QuadTree(new Region_1.Region(c, _this.bb.max), _this.capacity, _this.maxDepth, _this.depth + 1),
            };
            for (var _i = 0, _a = _this.objects; _i < _a.length; _i++) {
                var object = _a[_i];
                _this.insert(object);
            }
        };
        this.queryRange = function (bb) {
            var objectsInRange = [];
            if (!_this.bb.intersect(bb))
                return objectsInRange;
            for (var _i = 0, _a = _this.objects; _i < _a.length; _i++) {
                var o = _a[_i];
                bb.intersect(o.bb) && objectsInRange.push(o.data);
            }
            if (!_this.sides)
                return objectsInRange;
            var _b = _this.sides, nw = _b.nw, ne = _b.ne, sw = _b.sw, se = _b.se;
            var allObjectsInRange = objectsInRange
                .concat(nw.queryRange(bb))
                .concat(ne.queryRange(bb))
                .concat(se.queryRange(bb))
                .concat(sw.queryRange(bb));
            return allObjectsInRange.filter(function (o, i) { return allObjectsInRange.indexOf(o) === i; });
        };
        this.pick = function (e) {
            var goToTree = _this.pickTree(e);
            var objectInRegion = function (o) {
                return Region_1.Region.regionContains(o.bb, e);
            };
            var returnedObjects = goToTree === null || goToTree === void 0 ? void 0 : goToTree.objects.filter(objectInRegion);
            if (returnedObjects && returnedObjects.length > 0)
                return returnedObjects[returnedObjects.length - 1].data;
        };
        this.pickTree = function (e) {
            if (!_this.sides) {
                if (_this.bb.contains(e)) {
                    return _this;
                }
                return;
            }
            var returnedTree = _this.sides.ne.pickTree(e) ||
                _this.sides.nw.pickTree(e) ||
                _this.sides.se.pickTree(e) ||
                _this.sides.sw.pickTree(e);
            if (!returnedTree) {
                throw new Error("Cannot find element for coords " + e.x + ", " + e.y);
            }
            return returnedTree;
        };
    }
    return QuadTree;
}());
exports.QuadTree = QuadTree;
