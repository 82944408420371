"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultDiagramThemeLight = void 0;
var DefaultDiagramTheme_1 = require("./DefaultDiagramTheme");
var Colors_1 = require("./Colors");
exports.DefaultDiagramThemeLight = __assign(__assign({}, JSON.parse(JSON.stringify(DefaultDiagramTheme_1.DefaultDiagramTheme))), { colors: {
        background: Colors_1.Colors.grey[10],
        node: {
            background: Colors_1.Colors.grey[2],
            selected: Colors_1.Colors.green[0],
            name: Colors_1.Colors.grey[11],
            type: Colors_1.Colors.grey[6],
            types: {
                string: Colors_1.Colors.green[0],
                type: Colors_1.Colors.grey[11],
            },
            options: {
                required: Colors_1.Colors.red[0],
                array: Colors_1.Colors.yellow[7],
            },
            hover: {
                type: Colors_1.Colors.yellow[0],
            },
            menuOpened: Colors_1.Colors.red[0],
        },
        minimap: {
            background: Colors_1.Colors.grey[0],
            visibleArea: Colors_1.Colors.grey[1],
            node: Colors_1.Colors.grey[11],
            borders: Colors_1.Colors.grey[4],
        },
        description: {
            background: Colors_1.Colors.grey[2],
            text: Colors_1.Colors.grey[11],
        },
        port: {
            background: Colors_1.Colors.grey[3],
            backgroundActive: Colors_1.Colors.grey[4],
            button: Colors_1.Colors.grey[0],
        },
        link: {
            main: Colors_1.Colors.grey[3],
            hover: Colors_1.Colors.yellow[0],
            active: Colors_1.Colors.green[0],
        },
        menu: {
            background: Colors_1.Colors.grey[2],
            borders: Colors_1.Colors.grey[3],
            text: Colors_1.Colors.grey[11],
            hover: Colors_1.Colors.grey[1],
        },
        help: {
            background: Colors_1.Colors.grey[2],
            text: Colors_1.Colors.grey[11],
            title: Colors_1.Colors.main[0],
        },
    } });
