"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeRenderer = void 0;
var RoundedRectangle_1 = require("./Draw/RoundedRectangle");
var MultilineText_1 = require("./Draw/MultilineText");
var Triangle_1 = require("./Draw/Triangle");
var NodeRenderer = (function () {
    function NodeRenderer(contextProvider, theme) {
        var _this = this;
        this.contextProvider = contextProvider;
        this.theme = theme;
        this.render = function (_a) {
            var _b, _c;
            var node = _a.node, typeIsHovered = _a.typeIsHovered, isHovered = _a.isHovered, isSelected = _a.isSelected;
            if (!node.hidden) {
                var _d = _this.theme, colors_1 = _d.colors, _e = _d.node, nodeWidth = _e.width, height_1 = _e.height, nameSize = _e.nameSize, typeSize = _e.typeSize, options_1 = _e.options, radius = _e.radius, typeYGap = _e.typeYGap, padding = _e.padding, description = _d.description;
                var width_1 = Math.max(nodeWidth, _this.context.measureText(node.name).width + padding * 2);
                _this.context.fillStyle =
                    ((_b = colors_1.node.backgrounds) === null || _b === void 0 ? void 0 : _b[node.type]) || colors_1.node.background;
                (0, RoundedRectangle_1.RoundedRectangle)(_this.context, {
                    width: width_1,
                    height: height_1,
                    x: node.x,
                    y: node.y,
                    radius: radius,
                });
                _this.context.fillStyle =
                    ((_c = colors_1.node.types) === null || _c === void 0 ? void 0 : _c[node.type]) || colors_1.node.type;
                var typeContent = node.type;
                if (typeIsHovered) {
                    _this.context.fillStyle = colors_1.node.hover.type;
                    typeContent += " >";
                }
                _this.context.font = _this.getNodeFont(typeSize, "normal");
                _this.context.textBaseline = "bottom";
                _this.context.textAlign = "end";
                _this.context.fillText(typeContent, node.x + width_1, node.y - typeYGap);
                if (node.options) {
                    var xCounter_1 = 0;
                    node.options.forEach(function (o) {
                        _this.context.fillStyle = colors_1.node.options[o] || colors_1.node.name;
                        _this.context.font = _this.getNodeFont(options_1.fontSize, "normal");
                        _this.context.textAlign = "left";
                        _this.context.fillText(o, node.x + xCounter_1, node.y + height_1 + options_1.fontSize + options_1.yGap, width_1);
                        xCounter_1 += _this.context.measureText(o).width + options_1.fontSize / 2;
                    });
                }
                if (node.name) {
                    _this.context.fillStyle = colors_1.node.name;
                    _this.context.font = _this.getNodeFont(nameSize, "normal");
                    _this.context.textAlign = "center";
                    _this.context.textBaseline = "middle";
                    _this.context.fillText(node.name, node.x + width_1 / 2.0, node.y + height_1 / 2.0);
                }
                if (isHovered || isSelected) {
                    _this.context.strokeStyle = colors_1.node.selected;
                    _this.context.lineWidth = 2;
                    (0, RoundedRectangle_1.RoundedRectangleStroke)(_this.context, {
                        width: width_1,
                        height: height_1,
                        x: node.x,
                        y: node.y,
                        radius: radius,
                    });
                }
                if (isSelected) {
                    _this.context.fillStyle = colors_1.node.selected + "17";
                    (0, RoundedRectangle_1.RoundedRectangle)(_this.context, {
                        width: width_1,
                        height: height_1,
                        x: node.x,
                        y: node.y,
                        radius: radius,
                    });
                    if (node.description) {
                        _this.context.font = _this.getNodeFont(description.fontSize, "normal");
                        _this.context.fillStyle = "" + colors_1.description.background;
                        var dWidth = Math.max(description.width, width_1);
                        var x = node.x - (dWidth - width_1) / 2.0;
                        var numberOfLines = Math.ceil(_this.context.measureText(node.description).width /
                            (dWidth - description.paddingHorizontal * 2));
                        var lineHeight = description.fontSize * 1.5;
                        var textHeight = lineHeight * numberOfLines;
                        var y = node.y -
                            textHeight -
                            description.paddingVertical * 2 -
                            description.triangleDistance;
                        (0, RoundedRectangle_1.RoundedRectangle)(_this.context, {
                            width: dWidth,
                            height: textHeight + description.paddingVertical * 2,
                            x: x,
                            y: y,
                            radius: description.radius,
                        });
                        var center = x + dWidth / 2.0;
                        (0, Triangle_1.Triangle)(_this.context, center - description.triangleWidth / 2.0, node.y - description.triangleDistance, center + description.triangleWidth / 2.0, node.y - description.triangleDistance, center, node.y);
                        _this.context.fillStyle = colors_1.description.text;
                        _this.context.textAlign = "left";
                        _this.context.textBaseline = "top";
                        (0, MultilineText_1.MultilineText)(_this.context, {
                            lineHeight: lineHeight,
                            text: node.description,
                            width: dWidth - description.paddingHorizontal * 2,
                            x: x + description.paddingHorizontal,
                            y: y + description.paddingVertical,
                        });
                    }
                }
            }
        };
    }
    NodeRenderer.prototype.getNodeFont = function (size, weight) {
        if (weight === void 0) { weight = "normal"; }
        return weight + " " + size + "px " + this.theme.fontFamily;
    };
    Object.defineProperty(NodeRenderer.prototype, "context", {
        get: function () {
            return this.contextProvider.context;
        },
        enumerable: false,
        configurable: true
    });
    return NodeRenderer;
}());
exports.NodeRenderer = NodeRenderer;
