"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CSSMiniEngine = void 0;
var configuration_1 = require("../../configuration");
function classToStyle(classArgument, className, afterBefore) {
    var o;
    if (typeof classArgument === "function") {
        o = classArgument(configuration_1.ConfigurationManager.instance.getOption("theme"));
    }
    else {
        o = classArgument;
    }
    var elm = new Option();
    Object.keys(o).forEach(function (a) {
        elm.style[a] = o[a];
    });
    if (afterBefore) {
        return "." + className + afterBefore + "{\ncontent:'';" + elm.getAttribute("style") + "\n}";
    }
    else {
        return "." + className + afterBefore + "{\n" + elm.getAttribute("style") + "\n}";
    }
}
var CSSMiniEngine = (function () {
    function CSSMiniEngine() {
        var _this = this;
        this.classes = [];
        this.addClass = function (o, className, afterBefore) {
            if (afterBefore === void 0) { afterBefore = ""; }
            _this.classes.push(classToStyle(o, className, afterBefore));
        };
        this.compile = function () {
            var head = document.head || document.getElementsByTagName("head")[0];
            var style = document.createElement("style");
            head.appendChild(style);
            style.type = "text/css";
            style.appendChild(document.createTextNode(_this.classes.join("\n")));
        };
    }
    Object.defineProperty(CSSMiniEngine, "instance", {
        get: function () {
            return _instance;
        },
        enumerable: false,
        configurable: true
    });
    CSSMiniEngine.createStyle = function (style) { return style; };
    return CSSMiniEngine;
}());
exports.CSSMiniEngine = CSSMiniEngine;
var _instance = new CSSMiniEngine();
