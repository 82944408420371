"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkUtils = void 0;
var DefaultDiagramTheme_1 = require("../theme/DefaultDiagramTheme");
var LinkUtils = (function () {
    function LinkUtils() {
    }
    LinkUtils.linkToTree = function (l, theme) {
        if (theme === void 0) { theme = DefaultDiagramTheme_1.DefaultDiagramTheme; }
        var o = l.o, i = l.i;
        var xCenter = LinkUtils.calculateLinkXCenter(l, theme);
        return {
            data: l,
            bb: {
                min: {
                    x: xCenter - 15,
                    y: (o.y <= i.y ? o.y : i.y) + theme.node.height / 2.0,
                },
                max: {
                    x: xCenter + 15,
                    y: (o.y > i.y ? o.y : i.y) + theme.node.height / 2.0,
                },
            },
        };
    };
    LinkUtils.calculateLinkCenterPoint = function (link, e, theme) {
        if (theme === void 0) { theme = DefaultDiagramTheme_1.DefaultDiagramTheme; }
        var startX = link.o.x + theme.node.width;
        return (e.x - startX) / (link.i.x - startX);
    };
    LinkUtils.calculateLinkXCenter = function (link, theme) {
        if (theme === void 0) { theme = DefaultDiagramTheme_1.DefaultDiagramTheme; }
        return LinkUtils.calculateLinkXCenterMath(link.o.x + theme.node.width, link.i.x, link.centerPoint);
    };
    LinkUtils.calculateLinkXCenterMath = function (x1, x2, centerPoint) {
        var distance = (x1 - x2) * centerPoint;
        return x1 - distance;
    };
    return LinkUtils;
}());
exports.LinkUtils = LinkUtils;
