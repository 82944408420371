"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Colors = void 0;
exports.Colors = {
    grey: [
        "#FBFBFB",
        "#E6E6E6",
        "#CCCCCC",
        "#B2B2B2",
        "#808080",
        "#4D4D4D",
        "#333232",
        "#202020",
        "#1A1919",
        "#050505",
        "#E5E5E5",
        "#666666"
    ],
    main: [
        "#FF59C5",
        "#E650B1",
        "#CC479E",
        "#B33E8A",
        "#802D62",
        "#4D1B3B",
        "#331227"
    ],
    green: [
        "#30FFC8",
        "#2CE6B4",
        "#27CCA0",
        "#22B38C",
        "#188064",
        "#0F4D3C",
        "#0A3328"
    ],
    yellow: [
        "#FED531",
        "#E6C02C",
        "#CCAB27",
        "#B39622",
        "#806B18",
        "#4D400F",
        "#332B0A",
        "#C3A240"
    ],
    red: [
        "#FF306B",
        "#E62C60",
        "#CC2756",
        "#B3224B",
        "#801835",
        "#4D0F20",
        "#330A15"
    ],
};
