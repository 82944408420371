"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UIManager = void 0;
var configuration_1 = require("../../../configuration");
var PAN_EPSILON = 2;
var UIManager = (function () {
    function UIManager(state, eventBus, theme) {
        var _this = this;
        this.state = state;
        this.eventBus = eventBus;
        this.theme = theme;
        this.mouseWheel = function (_a) {
            var delta = _a.delta, position = _a.position;
            if (_this.state.animatingPan) {
                return false;
            }
            var scaleChange = delta / -800.0;
            var newScale = _this.state.scale + scaleChange;
            if (newScale < 0.3) {
                newScale = 0.3;
            }
            if (newScale > 1.5) {
                newScale = 1.5;
            }
            _this.state.panX =
                _this.state.panX + position.x / newScale - position.x / _this.state.scale;
            _this.state.panY =
                _this.state.panY + position.y / newScale - position.y / _this.state.scale;
            _this.state.scale = newScale;
            _this.eventBus.publish('RenderRequested');
        };
        this.calculateAnimations = function (timeCoefficient) {
            if (!_this.state.animatingPan) {
                return false;
            }
            if (!_this.state.targetPanX || !_this.state.targetPanY) {
                return;
            }
            var _a = [
                _this.state.targetPanX - _this.state.panX,
                _this.state.targetPanY - _this.state.panY,
            ], deltaX = _a[0], deltaY = _a[1];
            if (Math.abs(deltaX) < PAN_EPSILON && Math.abs(deltaY) < PAN_EPSILON) {
                _this.state.panX = _this.state.targetPanX;
                _this.state.panY = _this.state.targetPanY;
                _this.state.animatingPan = false;
                return false;
            }
            _this.state.panX +=
                deltaX / Math.max(_this.autoPanSmoothing / timeCoefficient, 1.0);
            _this.state.panY +=
                deltaY / Math.max(_this.autoPanSmoothing / timeCoefficient, 1.0);
            _this.eventBus.publish('RenderRequested');
            return true;
        };
        this.worldToScreen = function (_a) {
            var position = _a.position;
            return {
                x: (position.x + _this.state.panX) * _this.state.scale,
                y: (position.y + _this.state.panY) * _this.state.scale,
                shiftKey: position.shiftKey,
            };
        };
        this.screenToWorld = function (_a) {
            var position = _a.position;
            return {
                x: position.x / _this.state.scale - _this.state.panX,
                y: position.y / _this.state.scale - _this.state.panY,
                shiftKey: position.shiftKey,
            };
        };
        this.centerOnNode = function (_a) {
            var node = _a.node;
            if (!_this.autoPanSmoothing) {
                _this.state.panX =
                    -(node.x + _this.theme.node.width / 2) +
                        _this.state.areaSize.width / 2 / _this.state.scale;
                _this.state.panY =
                    -(node.y + _this.theme.node.height / 2) +
                        _this.state.areaSize.height / 2 / _this.state.scale;
            }
            else {
                _this.state.targetPanX =
                    -(node.x + _this.theme.node.width / 2) +
                        _this.state.areaSize.width / 2 / _this.state.scale;
                _this.state.targetPanY =
                    -(node.y + _this.theme.node.height / 2) +
                        _this.state.areaSize.height / 2 / _this.state.scale;
                _this.state.animatingPan = true;
            }
            _this.eventBus.publish('RenderRequested');
        };
        this.calculateMinimapPosition = function (_a) {
            var position = _a.position;
            var minimapConstraints = {
                left: _this.state.areaSize.width -
                    _this.theme.minimap.size -
                    _this.theme.minimap.margin,
                right: _this.state.areaSize.width - _this.theme.minimap.margin,
                top: _this.theme.minimap.margin,
                bottom: _this.theme.minimap.margin + _this.theme.minimap.size,
            };
            if (position.x > minimapConstraints.left &&
                position.x < minimapConstraints.right &&
                position.y > minimapConstraints.top &&
                position.y < minimapConstraints.bottom) {
                return {
                    x: position.x - minimapConstraints.left,
                    y: position.y - minimapConstraints.top,
                };
            }
            return null;
        };
        this.mouseOverMove = function (_a) {
            var position = _a.position;
            var minimapPosition = _this.calculateMinimapPosition({ position: position });
            if (minimapPosition && !_this.state.draggingWorld) {
                return;
            }
            _this.eventBus.publish('WorldMouseOverMove', {
                position: _this.screenToWorld({ position: position }),
            });
        };
        this.mouseMove = function (_a) {
            var position = _a.position;
            var minimapPosition = _this.calculateMinimapPosition({ position: position });
            if (minimapPosition && !_this.state.draggingWorld) {
                _this.eventBus.publish('MinimapMouseMove', { position: minimapPosition });
                return;
            }
            _this.eventBus.publish('WorldMouseMove', {
                position: _this.screenToWorld({ position: position }),
            });
        };
        this.mouseDrag = function (_a) {
            var position = _a.position;
            var isInsideMinimap = _this.calculateMinimapPosition({ position: position });
            if (isInsideMinimap) {
                return;
            }
            var calculated = _this.screenToWorld({ position: position });
            _this.eventBus.publish('WorldMouseDrag', {
                withoutPan: {
                    x: calculated.x + _this.state.panX,
                    y: calculated.y + _this.state.panY,
                    shiftKey: calculated.shiftKey,
                },
                calculated: calculated,
            });
        };
        this.LMBUp = function (_a) {
            var position = _a.position;
            _this.eventBus.publish('WorldLeftMouseUp', {
                position: _this.screenToWorld({ position: position }),
            });
            _this.state.draggingWorld = false;
            _this.state.draggingMinimap = false;
        };
        this.LMBPressed = function (_a) {
            var position = _a.position;
            var coordsInsideMinimap = _this.calculateMinimapPosition({ position: position });
            if (coordsInsideMinimap) {
                _this.eventBus.publish('MinimapLeftMouseClick', {
                    position: coordsInsideMinimap,
                });
                return;
            }
            _this.state.lastDragPosition = {
                x: position.x / _this.state.scale,
                y: position.y / _this.state.scale,
            };
            _this.eventBus.publish('WorldLeftMouseClick', {
                position: _this.screenToWorld({ position: position }),
            });
        };
        this.panScreen = function (_a) {
            var position = _a.position;
            if (!_this.state.lastDragPosition) {
                return;
            }
            _this.state.animatingPan = false;
            _this.state.draggingWorld = true;
            _this.state.panX -= _this.state.lastDragPosition.x - position.x;
            _this.state.panY -= _this.state.lastDragPosition.y - position.y;
            _this.state.lastDragPosition = __assign({}, position);
            _this.eventBus.publish('RenderRequested');
        };
        this.panTo = function (_a) {
            var position = _a.position;
            _this.state.panX = -position.x;
            _this.state.panY = -position.y;
            _this.eventBus.publish('RenderRequested');
        };
        this.centerPanTo = function (_a) {
            var position = _a.position;
            _this.state.panX = position.x + _this.state.areaSize.width / 2.0;
            _this.state.panY = position.y + _this.state.areaSize.height / 2.0;
            _this.eventBus.publish('RenderRequested');
        };
        this.eventBus.subscribe('ScreenMouseMove', this.mouseMove);
        this.eventBus.subscribe('ScreenMouseOverMove', this.mouseOverMove);
        this.eventBus.subscribe('ScreenMouseWheel', this.mouseWheel);
        this.eventBus.subscribe('ScreenMouseDrag', this.mouseDrag);
        this.eventBus.subscribe('ScreenLeftMouseClick', this.LMBPressed);
        this.eventBus.subscribe('ScreenLeftMouseUp', this.LMBUp);
        this.eventBus.subscribe('PanRequested', this.panTo);
        this.eventBus.subscribe('CenterPanRequested', this.centerPanTo);
        this.eventBus.subscribe('CenterOnNode', this.centerOnNode);
        this.autoPanSmoothing =
            configuration_1.ConfigurationManager.instance.getOption('autoPanSmoothing');
    }
    UIManager.prototype.getViewModel = function () {
        return {
            pan: {
                x: this.state.panX,
                y: this.state.panY,
            },
            scale: this.state.scale,
        };
    };
    return UIManager;
}());
exports.UIManager = UIManager;
