"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deserializeLink = exports.serializeLink = void 0;
var serializeLink = function (_a) {
    var centerPoint = _a.centerPoint, i = _a.i, o = _a.o;
    return ({
        centerPoint: centerPoint,
        iId: i.id,
        oId: o.id,
    });
};
exports.serializeLink = serializeLink;
var deserializeLink = function (_a, nodes) {
    var centerPoint = _a.centerPoint, iId = _a.iId, oId = _a.oId;
    return ({
        centerPoint: centerPoint,
        i: nodes.find(function (n) { return n.id === iId; }),
        o: nodes.find(function (n) { return n.id === oId; }),
        circularReference: iId === oId,
    });
};
exports.deserializeLink = deserializeLink;
