"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextInLines = exports.MultilineText = void 0;
var MultilineText = function (context, _a) {
    var x = _a.x, y = _a.y, lineHeight = _a.lineHeight, width = _a.width, text = _a.text;
    var words = text.split(" ");
    var wordMeasure = words
        .map(function (w) { return context.measureText(w); })
        .map(function (w) { return w.width; });
    var spaceSize = context.measureText(" ").width;
    var currentWidth = 0;
    var currentString = "";
    var lines = [];
    var index = 0;
    for (var _i = 0, wordMeasure_1 = wordMeasure; _i < wordMeasure_1.length; _i++) {
        var wordWidth = wordMeasure_1[_i];
        var newWidth = currentWidth + wordWidth + spaceSize;
        if (newWidth > width) {
            lines.push(currentString);
            currentWidth = 0;
            currentString = "";
        }
        currentWidth += wordWidth + spaceSize;
        currentString += words[index] + " ";
        index++;
    }
    if (currentString) {
        lines.push(currentString);
    }
    for (var i = 0; i < lines.length; i++)
        context.fillText(lines[i], x, y + i * lineHeight);
    return lines;
};
exports.MultilineText = MultilineText;
var TextInLines = function (context, _a) {
    var width = _a.width, text = _a.text;
    var words = text.split(" ");
    var wordMeasure = words
        .map(function (w) { return context.measureText(w); })
        .map(function (w) { return w.width; });
    var spaceSize = context.measureText(" ").width;
    var currentWidth = 0;
    var currentString = "";
    var lines = 0;
    var index = 0;
    for (var _i = 0, wordMeasure_2 = wordMeasure; _i < wordMeasure_2.length; _i++) {
        var wordWidth = wordMeasure_2[_i];
        var newWidth = currentWidth + wordWidth + spaceSize;
        if (newWidth > width) {
            lines += 1;
            currentWidth = 0;
            currentString = "";
        }
        currentWidth += wordWidth + spaceSize;
        currentString += words[index] + " ";
        index++;
    }
    if (currentString) {
        lines += 1;
    }
    return lines;
};
exports.TextInLines = TextInLines;
