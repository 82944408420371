"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinimapRenderer = void 0;
var utils_1 = require("../utils");
var MinimapRenderer = (function () {
    function MinimapRenderer() {
    }
    MinimapRenderer.prototype.render = function (context, theme, state) {
        var uiState = state.uiState;
        if (!uiState.minimapActive || state.screenShotInProgress) {
            return;
        }
        context.save();
        context.globalAlpha = state.hoverMinimap
            ? theme.minimap.hoverAlpha
            : theme.minimap.alpha;
        var minimapStartX = context.canvas.width - theme.minimap.size - theme.minimap.margin;
        var minimapStartY = theme.minimap.margin;
        var boundingBoxViewport = utils_1.MinimapUtils.getBoundingBoxViewport({
            x: uiState.panX,
            y: uiState.panY,
        }, uiState.scale, state.uiState.areaSize);
        var miniMapBoundaries = utils_1.MinimapUtils.getMiniMapBoundaries(boundingBoxViewport, state.uiState.areaSize);
        var viewportCoord = utils_1.MinimapUtils.worldToMapPoint({
            x: boundingBoxViewport.left,
            y: boundingBoxViewport.top,
        }, theme.minimap.size, miniMapBoundaries);
        var areaCoordinates = {
            width: utils_1.MinimapUtils.worldToMapWidth(boundingBoxViewport.width, miniMapBoundaries, theme.minimap.size),
            height: utils_1.MinimapUtils.worldToMapHeight(boundingBoxViewport.height, miniMapBoundaries, theme.minimap.size),
            left: viewportCoord.x - 1,
            top: viewportCoord.y - 1,
        };
        context.fillStyle = theme.colors.minimap.background;
        context.fillRect(minimapStartX, minimapStartY, theme.minimap.size, theme.minimap.size);
        context.strokeStyle = theme.colors.minimap.borders;
        context.lineWidth = 1;
        context.strokeRect(minimapStartX, minimapStartY, theme.minimap.size, theme.minimap.size);
        context.beginPath();
        context.rect(minimapStartX, minimapStartY, theme.minimap.size, theme.minimap.size);
        context.clip();
        context.fillStyle = theme.colors.minimap.visibleArea;
        context.fillRect(minimapStartX + areaCoordinates.left, minimapStartY + areaCoordinates.top, areaCoordinates.width, areaCoordinates.height);
        context.lineWidth = 1;
        context.strokeRect(minimapStartX + areaCoordinates.left, minimapStartY + areaCoordinates.top, areaCoordinates.width, areaCoordinates.height);
        context.fillStyle = theme.colors.minimap.node;
        state.nodes
            .filter(function (n) { return !n.hidden; })
            .forEach(function (n) {
            var nodePos = utils_1.MinimapUtils.worldToMapPoint(n, theme.minimap.size, miniMapBoundaries);
            context.fillRect(minimapStartX + nodePos.x, minimapStartY + nodePos.y, 6, 4);
        });
        context.restore();
    };
    return MinimapRenderer;
}());
exports.MinimapRenderer = MinimapRenderer;
