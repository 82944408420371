"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StateManager = void 0;
var nodeManager_1 = require("./nodeManager");
var connectionManager_1 = require("./connectionManager");
var uiManager_1 = require("./uiManager");
var minimapManager_1 = require("./minimapManager");
var quadTree_1 = require("../../quadTree");
var hoverManager_1 = require("./hoverManager");
var StateManager = (function () {
    function StateManager(eventBus, theme, areaSize) {
        var _this = this;
        this.eventBus = eventBus;
        this.theme = theme;
        this.pureState = function () { return _this.state; };
        this.rebuildTrees = function () {
            _this.nodeManager.rebuildTree();
            _this.connectionManager.rebuildTree();
        };
        this.calculateAnimations = function (timeCoefficient) {
            return _this.uiManager.calculateAnimations(timeCoefficient);
        };
        this.centerGraph = function () {
            _this.uiManager.centerPanTo({ position: _this.nodeManager.getCenter() });
        };
        this.selectNode = function (node) {
            _this.nodeManager.selectSingleNode(node);
        };
        this.zeroGraph = function () {
            _this.uiManager.panTo({
                position: {
                    x: -_this.theme.node.width * 3,
                    y: -_this.theme.node.height * 3,
                },
            });
        };
        this.centerOnNode = function (node) {
            var foundIndex = _this.state.nodes.indexOf(node);
            if (foundIndex > -1) {
                _this.eventBus.publish('CenterOnNode', { node: node });
            }
        };
        this.mouseDrag = function (_a) {
            var withoutPan = _a.withoutPan;
            if (_this.state.uiState.draggingMinimap) {
                return;
            }
            var selectedNodes = _this.state.selectedNodes;
            if (selectedNodes.length > 0) {
                return;
            }
            _this.uiManager.panScreen({ position: withoutPan });
        };
        this.areaResized = function (newSize) {
            _this.state.uiState.areaSize = newSize;
            _this.eventBus.publish('RenderRequested');
        };
        this.worldToScreenCoordinates = function (position) {
            return _this.uiManager.worldToScreen({ position: position });
        };
        this.state = {
            links: [],
            nodes: [],
            selectedNodes: [],
            hover: {},
            hoverMinimap: false,
            trees: {
                node: new quadTree_1.QuadTree(),
                link: new quadTree_1.QuadTree(),
            },
            uiState: {
                minimapActive: true,
                panX: 0,
                panY: 0,
                scale: 1.0,
                lastDragPosition: {
                    x: 0,
                    y: 0,
                },
                areaSize: areaSize,
                draggingWorld: false,
                draggingMinimap: false,
                animatingPan: false,
            },
            screenShotInProgress: false,
        };
        this.uiManager = new uiManager_1.UIManager(this.state.uiState, this.eventBus, this.theme);
        this.connectionManager = new connectionManager_1.ConnectionManager(this.state, this.theme);
        this.nodeManager = new nodeManager_1.NodeManager(this.state, this.eventBus, this.theme);
        new hoverManager_1.HoverManager(this.state, this.eventBus);
        new minimapManager_1.MinimapManager(this.state, this.eventBus, this.theme);
        this.eventBus.subscribe('WorldMouseDrag', this.mouseDrag);
        this.eventBus.subscribe('RebuildTreeRequested', this.rebuildTrees);
    }
    StateManager.prototype.getState = function () {
        return __assign({}, this.state);
    };
    StateManager.prototype.setNodes = function (nodes) {
        this.nodeManager.loadNodes(nodes);
    };
    StateManager.prototype.setLinks = function (links) {
        this.connectionManager.loadLinks(links);
    };
    StateManager.prototype.setScreenShotInProgress = function (screenShotInProgress) {
        this.state.screenShotInProgress = screenShotInProgress;
    };
    StateManager.prototype.isScreenShotInProgress = function () {
        return this.state.screenShotInProgress;
    };
    return StateManager;
}());
exports.StateManager = StateManager;
