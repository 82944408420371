import './App.css'

import { useState } from 'react'
import { GraphQLEditor } from 'graphql-editor'
import { useLocalStorage } from '@rehooks/local-storage'

const schemas = {
  pizza: `
type Pizza{
    name: String
}

type Query{
	pizzas: [Pizza!]
}
`,
  pizzaLibrary: null,
}

const Home = () => {
  const [mySchema, setMySchema] = useLocalStorage('schema', {
    code: schemas.pizza,
    libraries: schemas.pizzaLibrary,
  })

  return (
    <div
      style={{
        flex: 1,
        width: '100%',
        height: '100%',
        alignSelf: 'stretch',
        display: 'flex',
        position: 'relative',
      }}
    >
      <GraphQLEditor
        // onSchemaChange={(props) => {
        //   setMySchema(props)
        // }}
        onStateChange={(props) => {
          console.log(props)
        }}
        setSchema={(passedSchema, isInvalid) => {
          // if (!isInvalid) {
          setMySchema(passedSchema)
          // }
          console.log({ passedSchema })
        }}
        schema={mySchema}
      />
    </div>
  )
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default Home
