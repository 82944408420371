"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkRenderer = void 0;
var QuadraticPath_1 = require("./Draw/QuadraticPath");
var LinkRenderer = (function () {
    function LinkRenderer(contextProvider, theme) {
        var _this = this;
        this.contextProvider = contextProvider;
        this.theme = theme;
        this.render = function (l, status) {
            var _a = _this.theme.node, width = _a.width, height = _a.height;
            var context = _this.contextProvider.context;
            if (l.i.hidden || l.o.hidden) {
                return;
            }
            if (!l.i.hidden) {
                return (0, QuadraticPath_1.QuadraticPath)(context, l.o.x, l.o.y + height / 2.0, l.i.x + width, l.i.y + height / 2.0, _this.theme.link.cornerRadius, _this.theme.link.strokeWidth, _this.theme.colors.link[status], l.centerPoint || _this.theme.link.defaultCenterPoint, l.o.x === l.i.x && l.o.y === l.i.y);
            }
        };
    }
    return LinkRenderer;
}());
exports.LinkRenderer = LinkRenderer;
