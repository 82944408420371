"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuadraticPath = void 0;
var utils_1 = require("../../utils");
var NODE_CR_HORIZONTAL_VECTOR = 150;
var NODE_CR_VERTICAL_VECTOR = 50;
var NODE_CIRCULAR_VECTOR = 2.5;
var QuadraticPath = function (context, x1, y1, x2, y2, cornerRadius, strokeWidth, color, centerPoint, circularReference) {
    if (centerPoint === void 0) { centerPoint = 0.5; }
    context.beginPath();
    context.moveTo(x1, y1);
    if (circularReference) {
        context.lineTo(x1 + 100 + NODE_CIRCULAR_VECTOR, y1);
        context.arcTo(x1 + NODE_CR_HORIZONTAL_VECTOR, y1, x1 + NODE_CR_HORIZONTAL_VECTOR + 50, y1 + 2 * NODE_CR_VERTICAL_VECTOR, cornerRadius);
        context.arcTo(x1 + NODE_CR_HORIZONTAL_VECTOR + NODE_CIRCULAR_VECTOR, y1 + 3 * NODE_CR_VERTICAL_VECTOR, x1 + NODE_CR_HORIZONTAL_VECTOR, y1 + 3 * NODE_CR_VERTICAL_VECTOR, cornerRadius);
        context.arcTo(x2 - NODE_CR_HORIZONTAL_VECTOR - NODE_CIRCULAR_VECTOR, y1 + 3 * NODE_CR_VERTICAL_VECTOR, x2 - NODE_CR_HORIZONTAL_VECTOR, y1 - 3 * NODE_CR_VERTICAL_VECTOR, cornerRadius);
        context.arcTo(x2 - NODE_CR_HORIZONTAL_VECTOR - NODE_CIRCULAR_VECTOR, y1, x2 - NODE_CR_HORIZONTAL_VECTOR, y1, cornerRadius);
        context.lineTo(x2, y2);
    }
    else {
        var centerX = utils_1.LinkUtils.calculateLinkXCenterMath(x1, x2, centerPoint);
        var ydiff = Math.abs(y2 - y1);
        var cr = ydiff > cornerRadius * 2 ? cornerRadius : Math.floor(ydiff / 2.0);
        var crx = x2 > x1 ? cr : -cr;
        var cry = y2 > y1 ? cr : -cr;
        context.beginPath();
        context.moveTo(x1, y1);
        context.lineTo(centerX - crx, y1);
        context.arcTo(centerX, y1, centerX, y1 + cry, cr);
        context.lineTo(centerX, y2 - cry);
        context.arcTo(centerX, y2, centerX + crx, y2, cr);
        context.lineTo(x2, y2);
    }
    context.lineWidth = strokeWidth;
    context.strokeStyle = color;
    context.stroke();
};
exports.QuadraticPath = QuadraticPath;
