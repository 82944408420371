"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinimapUtils = void 0;
var MINIMAP_RANGE = 7000;
var MinimapUtils = (function () {
    function MinimapUtils() {
    }
    MinimapUtils.getBoundingBoxViewport = function (pan, scale, graphSize) {
        return {
            left: -pan.x,
            right: graphSize.width / scale - pan.x,
            top: -pan.y,
            bottom: graphSize.height / scale - pan.y,
            width: graphSize.width / scale,
            height: graphSize.height / scale,
        };
    };
    MinimapUtils.getMiniMapBoundaries = function (viewportBoundingBox, graphSize) {
        return {
            left: Math.max(Math.min(-MINIMAP_RANGE + graphSize.width / 2, viewportBoundingBox.left), viewportBoundingBox.right - 2 * MINIMAP_RANGE),
            top: Math.max(Math.min(-MINIMAP_RANGE + graphSize.height / 2, viewportBoundingBox.top), viewportBoundingBox.bottom - 2 * MINIMAP_RANGE),
            width: 2 * MINIMAP_RANGE,
            height: 2 * MINIMAP_RANGE,
        };
    };
    MinimapUtils.mapToWorldPoint = function (point, miniMapBoundaries, mapSize) {
        var delta = {
            x: (miniMapBoundaries.left + miniMapBoundaries.width / 2) /
                (miniMapBoundaries.width / mapSize),
            y: (miniMapBoundaries.top + miniMapBoundaries.height / 2) /
                (miniMapBoundaries.width / mapSize),
        };
        var ratio = miniMapBoundaries.width / mapSize;
        return {
            x: point.x * ratio - (mapSize / 2 - delta.x) * ratio,
            y: point.y * ratio - (mapSize / 2 - delta.y) * ratio,
        };
    };
    MinimapUtils.worldToMapWidth = function (size, miniMapBoundaries, mapSize) { return size / (miniMapBoundaries.width / (mapSize - 1)); };
    MinimapUtils.worldToMapHeight = function (size, miniMapBoundaries, mapSize) { return size / (miniMapBoundaries.height / (mapSize - 1)); };
    MinimapUtils.worldToMapPoint = function (coords, mapSize, miniMapBoundaries) {
        var delta = {
            x: MinimapUtils.worldToMapWidth(miniMapBoundaries.left + miniMapBoundaries.width / 2, miniMapBoundaries, mapSize),
            y: MinimapUtils.worldToMapHeight(miniMapBoundaries.top + miniMapBoundaries.height / 2, miniMapBoundaries, mapSize),
        };
        var ratio = miniMapBoundaries.width / (mapSize - 1);
        return {
            x: mapSize / 2 - delta.x + coords.x / ratio,
            y: mapSize / 2 - delta.y + coords.y / ratio,
        };
    };
    return MinimapUtils;
}());
exports.MinimapUtils = MinimapUtils;
