"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeManager = void 0;
var utils_1 = require("../../../utils");
var quadTree_1 = require("../../../quadTree");
var NodeManager = (function () {
    function NodeManager(state, eventBus, theme) {
        var _this = this;
        this.state = state;
        this.eventBus = eventBus;
        this.theme = theme;
        this.rebuildTree = function () {
            _this.state.trees.node = new quadTree_1.QuadTree();
            _this.state.nodes
                .filter(function (n) { return !n.hidden; })
                .forEach(function (n) {
                return _this.state.trees.node.insert(utils_1.NodeUtils.createTreeNode(n, _this.theme));
            });
        };
        this.loadNodes = function (nodes) {
            _this.state.nodes = nodes;
            _this.state.selectedNodes = [];
            _this.rebuildTree();
        };
        this.foldChildren = function (node, unfold) {
            var childrenToFold = utils_1.NodeUtils.findAllConnectedNodes(node, _this.state.nodes).filter(function (n) { return n !== node; });
            if (childrenToFold.length > 0) {
                childrenToFold.forEach(function (child) {
                    child.hidden = !unfold;
                });
                node.hideChildren = !unfold;
            }
            _this.eventBus.publish("RebuildTreeRequested");
            _this.eventBus.publish("RenderRequested");
        };
        this.selectSingleNodeByFunction = function (_a) {
            var fn = _a.fn;
            var node = _this.state.nodes.find(fn);
            if (node) {
                _this.selectSingleNode(node);
                _this.eventBus.publish("CenterOnNode", { node: node });
            }
        };
        this.selectSingleNode = function (node) {
            _this.state.selectedNodes = [node];
        };
        this.goToNodeType = function () {
            var _a = _this.state.hover, type = _a.type, node = _a.node;
            if (type && node) {
                var parentNode = _this.state.nodes.find(function (n) { return n.name === node.type; });
                if (parentNode) {
                    _this.selectSingleNode(parentNode);
                    _this.eventBus.publish("CenterOnNode", { node: parentNode });
                }
            }
        };
        this.selectNode = function (_a) {
            var position = _a.position;
            var _b = _this.state.hover, node = _b.node, type = _b.type;
            if (node && !type) {
                if (position.shiftKey) {
                    var hasIndex = _this.state.selectedNodes.indexOf(node);
                    if (hasIndex !== -1) {
                        _this.state.selectedNodes.splice(hasIndex);
                        return;
                    }
                    _this.state.selectedNodes.push(node);
                }
                else {
                    if (_this.state.selectedNodes.length === 1 ||
                        _this.state.selectedNodes.indexOf(node) === -1) {
                        _this.selectSingleNode(node);
                    }
                }
            }
            else {
                _this.state.selectedNodes = [];
            }
            _this.eventBus.publish("NodeSelected", {
                e: position,
                selectedNodes: __spreadArray([], _this.state.selectedNodes, true),
            });
            _this.eventBus.publish("RenderRequested");
        };
        this.getCenter = function () {
            var X = [];
            var Y = [];
            _this.state.nodes.forEach(function (n) { return X.push(n.x) && Y.push(n.y); });
            return {
                x: 0.5 * (Math.max.apply(Math, X) + Math.min.apply(Math, X)),
                y: 0.5 * (Math.max.apply(Math, Y) + Math.min.apply(Math, Y)),
            };
        };
        this.eventBus.subscribe("RequestNodeSelect", this.selectSingleNodeByFunction);
        this.eventBus.subscribe("WorldLeftMouseClick", this.selectNode);
        this.eventBus.subscribe("WorldLeftMouseClick", this.goToNodeType);
    }
    return NodeManager;
}());
exports.NodeManager = NodeManager;
