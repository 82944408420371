"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Region = void 0;
var MAX_INFINITY = 100000000000000000000;
var Region = (function () {
    function Region(min, max) {
        var _this = this;
        if (min === void 0) { min = { x: -MAX_INFINITY, y: -MAX_INFINITY }; }
        if (max === void 0) { max = { x: MAX_INFINITY, y: MAX_INFINITY }; }
        this.min = min;
        this.max = max;
        this.center = function () {
            return {
                x: (_this.min.x + _this.max.x) / 2.0,
                y: (_this.min.y + _this.max.y) / 2.0,
            };
        };
        this.intersect = function (bb) {
            return !(_this.max.x < bb.min.x ||
                bb.max.x < _this.min.x ||
                _this.max.y < bb.min.y ||
                bb.max.y < _this.min.y);
        };
        this.contains = function (p) { return Region.regionContains(_this, p); };
    }
    Region.regionContains = function (region, p) {
        return region.min.x <= p.x &&
            p.x <= region.max.x &&
            region.min.y <= p.y &&
            p.y <= region.max.y;
    };
    return Region;
}());
exports.Region = Region;
