"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeUtils = void 0;
var index_1 = require("./index");
var DefaultDiagramTheme_1 = require("../theme/DefaultDiagramTheme");
var rectanglePacker_1 = require("../rectanglePacker");
var NodeUtils = (function () {
    function NodeUtils() {
    }
    NodeUtils.createBasicNode = function (e, node) {
        if (node === void 0) { node = {}; }
        return __assign({ name: "Node", type: "type", id: index_1.Utils.generateId(), description: "Enter your description", x: e.x, y: e.y, inputs: [], outputs: [], options: [] }, node);
    };
    NodeUtils.findAllConnectedNodes = function (n, nodes) {
        var graphNodes = [];
        var spawnConnections = function (n) {
            if (graphNodes.find(function (no) { return no === n; }))
                return;
            graphNodes.push(n);
            n.inputs &&
                n.inputs.map(function (i) {
                    return spawnConnections(nodes.find(function (node) { return node.id === i; }));
                });
            n.outputs &&
                n.outputs.map(function (o) {
                    return spawnConnections(nodes.find(function (node) { return node.id === o; }));
                });
        };
        spawnConnections(n);
        return graphNodes;
    };
    NodeUtils.graphFromNode = function (n, nodes, theme) {
        var graphNodes = NodeUtils.findAllConnectedNodes(n, nodes);
        if (graphNodes.length === 0) {
            throw new Error("Invalid graph");
        }
        var findX = function (n) {
            var findOutputDepth = function (node, depth) {
                var _a;
                if (depth === void 0) { depth = 0; }
                if ((_a = node.outputs) === null || _a === void 0 ? void 0 : _a.length) {
                    var depths = node.outputs.map(function (no) {
                        return findOutputDepth(graphNodes.find(function (gn) { return gn.id === no; }), depth + 1);
                    });
                    return Math.max.apply(Math, depths);
                }
                return depth;
            };
            return findOutputDepth(n);
        };
        var levelised = [];
        graphNodes.forEach(function (n) {
            var depth = findX(n);
            if (!levelised[depth]) {
                levelised[depth] = [];
            }
            levelised[depth].push(n);
        });
        var maxY = Math.max.apply(Math, levelised.map(function (v) { return v.length; }));
        var positionedNodes = levelised
            .map(function (nodes, xOrder) {
            var heightOfColumn = nodes.length;
            var startY = (maxY - heightOfColumn) / 2.0;
            return nodes.map(function (n, order) {
                var x = -xOrder * (theme.node.width + theme.node.spacing.x);
                var y = (startY + order) * (theme.node.height + theme.node.spacing.y);
                return __assign(__assign({}, n), { x: x, y: y });
            });
        })
            .reduce(function (a, b) { return __spreadArray(__spreadArray([], a, true), b, true); });
        var graphBB = {
            min: {
                x: -levelised.length * (theme.node.width + theme.node.spacing.x),
                y: 0,
            },
            max: {
                x: 0,
                y: maxY * (theme.node.height + theme.node.spacing.y),
            },
        };
        var width = Math.abs(graphBB.min.x - graphBB.max.x);
        var height = Math.abs(graphBB.min.y - graphBB.max.y);
        return {
            nodes: positionedNodes,
            width: width,
            height: height,
            center: {
                x: graphBB.min.x + width / 2.0,
                y: graphBB.min.y + height / 2.0,
            },
        };
    };
    NodeUtils.graphsFromNodes = function (nodes, allNodes, theme) {
        var usedNodes = [];
        var graphs = [];
        var _loop_1 = function (node) {
            if (usedNodes.find(function (un) { return un.id === node.id; })) {
                return "continue";
            }
            var graph = NodeUtils.graphFromNode(node, allNodes, theme);
            usedNodes = usedNodes.concat(graph.nodes);
            graphs.push(graph);
        };
        for (var _i = 0, nodes_1 = nodes; _i < nodes_1.length; _i++) {
            var node = nodes_1[_i];
            _loop_1(node);
        }
        return graphs;
    };
    NodeUtils.beautifyDiagram = function (nodes, theme) {
        var graphs = NodeUtils.graphsFromNodes(nodes, nodes, theme);
        rectanglePacker_1.RectanglePacker.pack(graphs, theme);
        return graphs;
    };
    NodeUtils.createTreeNode = function (data, theme) {
        if (theme === void 0) { theme = DefaultDiagramTheme_1.DefaultDiagramTheme; }
        return ({
            data: data,
            bb: {
                min: {
                    x: data.x,
                    y: data.y - theme.node.typeSize,
                },
                max: {
                    x: data.x + theme.node.width,
                    y: data.y + theme.node.height,
                },
            },
        });
    };
    return NodeUtils;
}());
exports.NodeUtils = NodeUtils;
