"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Utils = exports.LinkUtils = exports.NodeUtils = exports.MinimapUtils = void 0;
var configuration_1 = require("../configuration");
var minimapUtils_1 = require("./minimapUtils");
Object.defineProperty(exports, "MinimapUtils", { enumerable: true, get: function () { return minimapUtils_1.MinimapUtils; } });
var nodeUtils_1 = require("./nodeUtils");
Object.defineProperty(exports, "NodeUtils", { enumerable: true, get: function () { return nodeUtils_1.NodeUtils; } });
var linkUtils_1 = require("./linkUtils");
Object.defineProperty(exports, "LinkUtils", { enumerable: true, get: function () { return linkUtils_1.LinkUtils; } });
var Utils = (function () {
    function Utils() {
    }
    Utils.debounce = function (func, wait) {
        var timeoutID;
        return function (t) {
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            clearTimeout(timeoutID);
            var context = t;
            timeoutID = window.setTimeout(function () {
                func.apply(context, args);
            }, wait);
        };
    };
    Utils.generateId = function () {
        return configuration_1.ConfigurationManager.instance.getOption("generateIdFn")();
    };
    Utils.getUniquePrefix = function (prefix) {
        if (prefix === void 0) { prefix = ""; }
        return "" + prefix + Math.floor(Math.random() * 9999999);
    };
    Utils.between = function (a, b) { return function (c) { return c >= a && c <= b; }; };
    Utils.clamp = function (v, min, max) {
        return Math.max(Math.min(v, max), min);
    };
    Utils.dedupe = function (a) { return a.filter(function (b, i) { return a.indexOf(b) === i; }); };
    Utils.deepCopy = function (o) { return JSON.parse(JSON.stringify(o)); };
    Utils.snap = function (e, snappingGridSize) { return (__assign(__assign({}, e), { x: Math.floor(e.x / snappingGridSize) * snappingGridSize, y: Math.floor(e.y / snappingGridSize) * snappingGridSize })); };
    Utils.componentToHex = function (c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    };
    Utils.rgbToHex = function (r, g, b) {
        return ("#" +
            Utils.componentToHex(r) +
            Utils.componentToHex(g) +
            Utils.componentToHex(b));
    };
    return Utils;
}());
exports.Utils = Utils;
