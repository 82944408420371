"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.whitespace = exports.packBoxes = void 0;
function packBoxes(sizes) {
    var layout = { size: [0, 0], boxes: [] };
    var order = new Array(sizes.length);
    for (var i = 0; i < sizes.length; i++) {
        order[i] = i;
    }
    order.sort(function (a, b) {
        return (sizes[b].dimensions[0] * sizes[b].dimensions[1] -
            sizes[a].dimensions[0] * sizes[a].dimensions[1]);
    });
    for (var i = 0; i < sizes.length; i++) {
        var size = sizes[order[i]].dimensions;
        var positions = [[0, 0]];
        for (var j = 0; j < layout.boxes.length; j++) {
            var box_1 = layout.boxes[j];
            positions.push([box_1.position[0], box_1.position[1] + box_1.size[1]], [box_1.position[0] + box_1.size[0], box_1.position[1]]);
        }
        var best = { score: Infinity, position: positions[0] };
        if (positions.length > 1) {
            for (var j = 0; j < positions.length; j++) {
                var position = positions[j];
                var box_2 = { size: size, position: position };
                if (validate(layout.boxes, box_2)) {
                    var boxes_1 = layout.boxes.slice();
                    boxes_1.push(box_2);
                    var score = rate({
                        size: bounds(boxes_1),
                        boxes: boxes_1,
                    });
                    if (score < best.score) {
                        best.score = score;
                        best.position = position;
                    }
                }
            }
        }
        var box = { size: size, position: best.position };
        layout.boxes.push(box);
        layout.size = bounds(layout.boxes);
    }
    var boxes = layout.boxes.slice();
    for (var i = 0; i < boxes.length; i++) {
        layout.boxes[order[i]] = boxes[i];
    }
    return boxes.map(function (box, i) {
        return ({
            index: order[i],
            box: box,
        });
    });
}
exports.packBoxes = packBoxes;
function rate(layout) {
    return Math.max(layout.size[0], layout.size[1]);
}
function whitespace(layout) {
    var whitespace = layout.size[0] * layout.size[1];
    for (var i = 0; i < layout.boxes.length; i++) {
        var box = layout.boxes[i];
        whitespace -= box.size[0] * box.size[1];
    }
    return whitespace;
}
exports.whitespace = whitespace;
function bounds(boxes) {
    var width = 0;
    var height = 0;
    for (var i = 0; i < boxes.length; i++) {
        var box = boxes[i];
        var right = box.position[0] + box.size[0];
        var bottom = box.position[1] + box.size[1];
        if (right > width) {
            width = right;
        }
        if (bottom > height) {
            height = bottom;
        }
    }
    return [width, height];
}
function validate(boxes, box) {
    var a = box;
    for (var i = 0; i < boxes.length; i++) {
        var b = boxes[i];
        if (intersects(a, b)) {
            return false;
        }
    }
    return true;
}
function intersects(a, b) {
    return (a.position[0] < b.position[0] + b.size[0] &&
        a.position[0] + a.size[0] > b.position[0] &&
        a.position[1] < b.position[1] + b.size[1] &&
        a.position[1] + a.size[1] > b.position[1]);
}
