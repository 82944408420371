"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContextProvider = void 0;
var ContextProvider = (function () {
    function ContextProvider(initialContext) {
        this._context = initialContext;
    }
    ContextProvider.prototype.switchContext = function (context) {
        this._context = context;
    };
    Object.defineProperty(ContextProvider.prototype, "context", {
        get: function () {
            return this._context;
        },
        enumerable: false,
        configurable: true
    });
    return ContextProvider;
}());
exports.ContextProvider = ContextProvider;
