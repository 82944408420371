"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Diagram = void 0;
var index_1 = require("../renderer/index");
var eventBus_1 = require("../eventBus");
var stateManager_1 = require("./stateManager");
var io_1 = require("../io");
var utils_1 = require("../utils");
var configuration_1 = require("../configuration");
var index_2 = require("../renderer/CssMiniEngine/index");
var Diagram = (function () {
    function Diagram(hostDomElement, options) {
        var _this = this;
        this.hostDomElement = hostDomElement;
        this.setTheme = function (theme) {
            _this.configuration.setOption("theme", theme);
            _this.eventBus.publish("RenderRequested");
        };
        this.autoResize = function () {
            if (_this.stateManager.isScreenShotInProgress()) {
                return;
            }
            var newHostSize = _this.calculateElementSize(_this.hostDomElement);
            if (newHostSize.width !== _this.currentHostSize.width ||
                newHostSize.height !== _this.currentHostSize.height) {
                _this.currentHostSize = newHostSize;
                var areaSize = {
                    width: newHostSize.width * 2,
                    height: newHostSize.height * 2,
                };
                _this.canvasElement.width = areaSize.width;
                _this.canvasElement.height = areaSize.height;
                _this.canvasElement.style.width = "100%";
                _this.canvasElement.style.height = "100%";
                _this.stateManager.areaResized({
                    width: _this.canvasElement.width,
                    height: _this.canvasElement.height,
                });
                _this.io.calculateClientBoundingRect();
            }
        };
        this.screenShot = function (type) {
            if (type === void 0) { type = "image/png"; }
            return __awaiter(_this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    return [2, new Promise(function (resolve) {
                            _this.stateManager.setScreenShotInProgress(true);
                            var currentNodes = _this.stateManager.pureState().nodes;
                            var screenShotMargin = _this.configuration.getOption("screenShotMargin");
                            var _a = _this.configuration.getOption("theme").node, nodeWidth = _a.width, nodeHeight = _a.height;
                            var rangeX = currentNodes.reduce(function (acc, cur) { return [Math.min(cur.x, acc[0]), Math.max(cur.x, acc[1])]; }, [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER]);
                            var rangeY = currentNodes.reduce(function (acc, cur) { return [Math.min(cur.y, acc[0]), Math.max(cur.y, acc[1])]; }, [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER]);
                            var savedUiState = __assign({}, _this.stateManager.getState().uiState);
                            _this.renderer.createScreenShotContext(rangeX[0] - screenShotMargin, rangeX[1] + screenShotMargin + nodeWidth, rangeY[0] - screenShotMargin, rangeY[1] + screenShotMargin + nodeHeight);
                            if (savedUiState) {
                                var screenShotRenderedCallback_1 = function (_a) {
                                    var context = _a.context;
                                    context.canvas.toBlob(function (blob) {
                                        resolve(blob);
                                    }, type);
                                    _this.stateManager.setScreenShotInProgress(false);
                                    _this.renderer.releaseScreenShotContext();
                                    _this.stateManager.getState().uiState.panX = savedUiState.panX;
                                    _this.stateManager.getState().uiState.panY = savedUiState.panY;
                                    _this.stateManager.getState().uiState.scale = savedUiState.scale;
                                    _this.eventBus.off("ScreenShotRendered", screenShotRenderedCallback_1);
                                };
                                _this.eventBus.on("ScreenShotRendered", screenShotRenderedCallback_1);
                                _this.eventBus.publish("RenderRequested");
                            }
                        })];
                });
            });
        };
        if (typeof document === "undefined") {
            throw new Error("Diagram can work only in DOM-enabled environment (e.g. browser)!");
        }
        this.configuration = new configuration_1.ConfigurationManager(options || {});
        this.canvasElement = document.createElement("canvas");
        this.canvasElement.tabIndex = -1;
        this.canvasElement.style.outline = "none";
        var canvasContext = this.canvasElement.getContext("2d");
        if (!canvasContext) {
            throw new Error("Can't create canvas context!");
        }
        canvasContext.font = "10px " + this.configuration.getOption("theme").fontFamily;
        var hostSize = this.calculateElementSize(hostDomElement);
        this.currentHostSize = hostSize;
        this.canvasElement.oncontextmenu = function () { return false; };
        var targetWidth = this.configuration.getOption("width") || hostSize.width;
        var targetHeight = this.configuration.getOption("height") || hostSize.height;
        var areaSize = {
            width: targetWidth * 2,
            height: targetHeight * 2,
        };
        this.canvasElement.width = areaSize.width;
        this.canvasElement.height = areaSize.height;
        this.canvasElement.style.width = targetWidth + "px";
        this.canvasElement.style.height = targetHeight + "px";
        while (hostDomElement.firstChild) {
            hostDomElement.removeChild(hostDomElement.firstChild);
        }
        if (window.getComputedStyle(hostDomElement).position === "static") {
            hostDomElement.style.position = "relative";
        }
        hostDomElement.appendChild(this.canvasElement);
        this.eventBus = new eventBus_1.EventBus();
        this.io = new io_1.IO(this.eventBus, this.canvasElement);
        this.stateManager = new stateManager_1.StateManager(this.eventBus, this.configuration.getOption("theme"), areaSize);
        this.renderer = new index_1.Renderer(this.eventBus, canvasContext, this.stateManager, this.configuration.getOption("theme"));
        if (this.configuration.getOption("autosizeWatcher")) {
            setInterval(this.autoResize, this.configuration.getOption("autosizeInterval"));
        }
        index_2.CSSMiniEngine.instance.compile();
        this.wireUpResizer();
        this.renderer.renderStart();
    }
    Diagram.prototype.setNodes = function (nodes) {
        var positionedGraphs = utils_1.NodeUtils.beautifyDiagram(nodes, this.configuration.getOption("theme"));
        var allNodes = positionedGraphs
            .map(function (pg) { return pg.nodes; })
            .reduce(function (a, b) { return __spreadArray(__spreadArray([], a, true), b, true); }, []);
        var links = [];
        var rollingIndex = 1;
        allNodes.forEach(function (n) {
            var _a;
            (_a = n.inputs) === null || _a === void 0 ? void 0 : _a.forEach(function (inp) {
                var i = allNodes.find(function (an) { return an.id === inp; });
                if (!i) {
                    throw new Error("Invalid inputs array on node " + n.name + " " + JSON.stringify(n.inputs));
                }
                links.push({
                    i: i,
                    o: n,
                    centerPoint: 0.35 + rollingIndex / 50.0,
                });
            });
            rollingIndex = (rollingIndex + 1) % 20;
        });
        this.stateManager.setNodes(allNodes);
        this.stateManager.setLinks(links);
        this.eventBus.publish("RenderRequested");
    };
    Diagram.prototype.setLinks = function (links) {
        this.stateManager.setLinks(links);
    };
    Diagram.prototype.centerOnNode = function (node) {
        this.stateManager.centerOnNode(node);
    };
    Diagram.prototype.selectNode = function (node) {
        this.stateManager.selectNode(node);
    };
    Diagram.prototype.rebuildTrees = function () {
        this.stateManager.rebuildTrees();
    };
    Diagram.prototype.beautifyDiagram = function () {
        utils_1.NodeUtils.beautifyDiagram(this.stateManager.getState().nodes, this.configuration.getOption("theme"));
    };
    Diagram.prototype.forceRender = function () {
        this.eventBus.publish("RenderRequested");
    };
    Diagram.prototype.centerDiagram = function () {
        this.stateManager.centerGraph();
    };
    Diagram.prototype.zeroDiagram = function () {
        this.stateManager.zeroGraph();
    };
    Diagram.prototype.resize = function (width, height) {
        var targetSize = {
            width: width,
            height: height,
        };
        this.currentHostSize = targetSize;
        var areaSize = {
            width: targetSize.width * 2,
            height: targetSize.height * 2,
        };
        this.canvasElement.width = areaSize.width;
        this.canvasElement.height = areaSize.height;
        this.canvasElement.style.width = targetSize.width + "px";
        this.canvasElement.style.height = targetSize.height + "px";
        this.stateManager.areaResized({
            width: this.canvasElement.width,
            height: this.canvasElement.height,
        });
        this.io.calculateClientBoundingRect();
    };
    Diagram.prototype.calculateElementSize = function (domElement) {
        return { width: domElement.clientWidth, height: domElement.clientHeight };
    };
    Diagram.prototype.wireUpResizer = function () {
        var _this = this;
        if (this.configuration.getOption("autosizeOnWindowResize")) {
            window.addEventListener("resize", function () {
                _this.autoResize();
            });
        }
    };
    return Diagram;
}());
exports.Diagram = Diagram;
