"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IO = void 0;
__exportStar(require("./ScreenPosition"), exports);
var IO = (function () {
    function IO(eventBus, element) {
        var _this = this;
        this.element = element;
        this.lastClick = Date.now();
        this.touchStartTime = Date.now();
        this.hasContainerFocus = false;
        this.eventBus = eventBus;
        this.calculateClientBoundingRect();
        element.addEventListener('mouseleave', function (e) {
            e.preventDefault();
            _this.eventBus.publish('ScreenMouseLeave');
        });
        element.addEventListener('mousemove', function (event) {
            event.preventDefault();
            if (!_this.elementRect || !_this.hasContainerFocus) {
                return;
            }
            var e = { position: _this.createMouseEventPayload(event) };
            _this.eventBus.publish('ScreenMouseMove', e);
            if (event.buttons === 1) {
                _this.eventBus.publish('ScreenMouseDrag', e);
            }
            else {
                _this.eventBus.publish('ScreenMouseOverMove', e);
            }
        });
        element.addEventListener('wheel', function (e) {
            e.preventDefault();
            var delta = e.deltaMode === 1 ? e.deltaY * 24 : e.deltaY;
            var coords = _this.createMouseEventPayload(e);
            _this.eventBus.publish('ScreenMouseWheel', {
                delta: delta,
                position: coords,
            });
        });
        element.addEventListener('mouseup', function (event) {
            event.preventDefault();
            if (event.which === 1) {
                _this.eventBus.publish('ScreenLeftMouseUp', {
                    position: _this.createMouseEventPayload(event),
                });
            }
            else if (event.which === 3) {
                _this.eventBus.publish('ScreenRightMouseUp', {
                    position: _this.createMouseEventPayload(event),
                });
            }
        });
        element.addEventListener('mousedown', function (event) {
            _this.hasContainerFocus = true;
            if (event.which === 1) {
                _this.eventBus.publish('ScreenLeftMouseClick', {
                    position: _this.createMouseEventPayload(event, event.shiftKey),
                });
                var clickTime = Date.now();
                var diff = clickTime - _this.lastClick;
                if (diff < 250) {
                    _this.eventBus.publish('ScreenDoubleClick', {
                        position: _this.createMouseEventPayload(event),
                    });
                }
                _this.lastClick = clickTime;
            }
            else if (event.which === 3) {
                _this.eventBus.publish('ScreenRightMouseClick', {
                    position: _this.createMouseEventPayload(event),
                });
            }
        });
        element.addEventListener('keydown', function (event) {
            if (event.key === 'm') {
                _this.eventBus.publish('MPressed');
            }
            if (event.key === 'Delete') {
                _this.eventBus.publish('DeletePressed');
            }
            if (event.key === 'Backspace') {
                _this.eventBus.publish('BackspacePressed');
            }
        });
        element.addEventListener('touchstart', function (event) {
            event.preventDefault();
            _this.eventBus.publish('ScreenLeftMouseClick', {
                position: _this.createTouchEventPayload(event),
            });
            _this.touchStartTime = Date.now();
        });
        element.addEventListener('touchend', function (event) {
            event.preventDefault();
            var touchEndTime = Date.now();
            var diff = touchEndTime - _this.touchStartTime;
            if (diff > 500) {
                _this.eventBus.publish('ScreenRightMouseUp', {
                    position: _this.createTouchEventPayload(event),
                });
            }
            _this.touchStartTime = touchEndTime;
        });
    }
    IO.prototype.calculateClientBoundingRect = function () {
        this.elementRect = this.element.getBoundingClientRect();
    };
    IO.prototype.getReferenceRect = function () {
        return (this.elementRect || {
            left: 0,
            top: 0,
        });
    };
    IO.prototype.createMouseEventPayload = function (position, shiftKey) {
        if (shiftKey === void 0) { shiftKey = false; }
        var referenceRect = this.getReferenceRect();
        return {
            x: position.clientX * 2 - referenceRect.left * 2,
            y: position.clientY * 2 - referenceRect.top * 2,
            shiftKey: shiftKey,
        };
    };
    IO.prototype.createTouchEventPayload = function (position, shiftKey) {
        if (shiftKey === void 0) { shiftKey = false; }
        var referenceRect = this.getReferenceRect();
        return {
            x: position.changedTouches[0].clientX * 2 - referenceRect.left * 2,
            y: position.changedTouches[0].clientY * 2 - referenceRect.top * 2,
            shiftKey: shiftKey,
        };
    };
    return IO;
}());
exports.IO = IO;
