"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Serializer = void 0;
var Link_1 = require("./Link");
var Serializer = (function () {
    function Serializer() {
    }
    Serializer.serialize = function (state) {
        return {
            nodes: state.nodes,
            links: state.links.map(Link_1.serializeLink),
        };
    };
    Serializer.deserialize = function (state) {
        var links = state.links.map(function (l) { return (0, Link_1.deserializeLink)(l, state.nodes); });
        return {
            nodes: state.nodes,
            links: links,
        };
    };
    return Serializer;
}());
exports.Serializer = Serializer;
