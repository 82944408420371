"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventBus = void 0;
var EventBus = (function () {
    function EventBus() {
        this.topics = {};
        this.externalSubscribers = {};
        this.que = [];
    }
    EventBus.prototype.subscribe = function (topic, callback) {
        if (!this.topics[topic]) {
            this.topics[topic] = [];
        }
        this.topics[topic].push(callback);
    };
    EventBus.prototype.publish = function (topic, args) {
        this.que.push({
            topic: topic,
            args: args,
        });
        if (this.que.length > 1) {
            return;
        }
        var _loop_1 = function () {
            var q = this_1.que[0];
            var all = __spreadArray(__spreadArray([], (this_1.topics[q.topic] || []), true), (this_1.externalSubscribers[q.topic] || []), true);
            all.forEach(function (callback) { return callback(q.args); });
            this_1.que.shift();
        };
        var this_1 = this;
        while (this.que.length > 0) {
            _loop_1();
        }
    };
    EventBus.prototype.on = function (topic, callback) {
        var _this = this;
        if (!this.externalSubscribers[topic]) {
            this.externalSubscribers[topic] = [];
        }
        this.externalSubscribers[topic].push(callback);
        return function () { return _this.off(topic, callback); };
    };
    EventBus.prototype.off = function (topic, callback) {
        if (!this.externalSubscribers[topic]) {
            return;
        }
        this.externalSubscribers[topic] = this.externalSubscribers[topic].filter(function (existingCallback) { return existingCallback !== callback; });
    };
    return EventBus;
}());
exports.EventBus = EventBus;
