"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZoomPan = void 0;
var ZoomPan = (function () {
    function ZoomPan() {
    }
    ZoomPan.prototype.setCalculatedMatrix = function (context, uiState) {
        this.setUniformMatrix(context);
        context.scale(uiState.scale, uiState.scale);
        context.translate(uiState.panX, uiState.panY);
    };
    ZoomPan.prototype.setUniformMatrix = function (context) {
        context.setTransform(1, 0, 0, 1, 0, 0);
    };
    return ZoomPan;
}());
exports.ZoomPan = ZoomPan;
