"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer = void 0;
var minimapRenderer_1 = require("./minimapRenderer");
var zoomPan_1 = require("./zoomPan");
var nodeRenderer_1 = require("./nodeRenderer");
var linkRenderer_1 = require("./linkRenderer");
var quadTree_1 = require("../quadTree");
var CssMiniEngine_1 = require("./CssMiniEngine");
var ContextProvider_1 = require("./ContextProvider");
var configuration_1 = require("../configuration");
var Renderer = (function () {
    function Renderer(eventBus, canvasContext, stateManager, theme) {
        var _this = this;
        this.eventBus = eventBus;
        this.canvasContext = canvasContext;
        this.stateManager = stateManager;
        this.theme = theme;
        this.minimapRenderer = new minimapRenderer_1.MinimapRenderer();
        this.zoomPan = new zoomPan_1.ZoomPan();
        this.cssMiniEngine = new CssMiniEngine_1.CSSMiniEngine();
        this.previousFrameTime = 0;
        this.screenShotCanvasContext = null;
        this.getAllNodesArea = function () {
            var nodes = _this.stateManager.pureState().nodes;
            var minPoint = nodes.reduce(function (acc, curr) { return ({
                x: Math.min(curr.x, acc.x),
                y: Math.min(curr.y, acc.y),
            }); }, {
                x: Number.MAX_SAFE_INTEGER,
                y: Number.MAX_SAFE_INTEGER,
            });
            var maxPoint = nodes.reduce(function (acc, curr) { return ({
                x: Math.max(curr.x, acc.x),
                y: Math.max(curr.y, acc.y),
            }); }, {
                x: Number.MIN_SAFE_INTEGER,
                y: Number.MIN_SAFE_INTEGER,
            });
            return new quadTree_1.Region(minPoint, maxPoint);
        };
        this.getActiveArea = function () {
            var uiState = _this.stateManager.getState().uiState;
            var width = uiState.areaSize.width / uiState.scale;
            var height = uiState.areaSize.height / uiState.scale;
            return new quadTree_1.Region({
                x: 0 - uiState.panX,
                y: 0 - uiState.panY,
            }, {
                x: width - uiState.panX,
                y: height - uiState.panY,
            });
        };
        this.renderStart = function () {
            window.requestAnimationFrame(_this.render);
        };
        this.renderUpdate = function () {
            window.requestAnimationFrame(_this.render);
        };
        this.animate = function (timeCoefficient) {
            return _this.stateManager.calculateAnimations(timeCoefficient);
        };
        this.calculateTimeDelta = function (timePassed) {
            if (_this.previousFrameTime === 0) {
                return 1.0;
            }
            else {
                return (timePassed - _this.previousFrameTime) / 16.0;
            }
        };
        this.resetTimeCounter = function () {
            _this.previousFrameTime = 0;
        };
        this.render = function (timePassed) {
            var timeCoefficient = _this.calculateTimeDelta(timePassed);
            _this.setScreenTransform();
            _this.renderBackground();
            _this.setWorldTransform();
            _this.renderLinks();
            _this.renderNodes();
            _this.setScreenTransform();
            _this.renderMinimap();
            _this.renderCursor();
            if (_this.animate(timeCoefficient)) {
                _this.previousFrameTime = timePassed;
            }
            else {
                _this.resetTimeCounter();
            }
            if (_this.stateManager.isScreenShotInProgress() &&
                _this.screenShotCanvasContext) {
                _this.eventBus.publish('ScreenShotRendered', {
                    context: _this.screenShotCanvasContext,
                });
            }
        };
        this.contextProvider = new ContextProvider_1.ContextProvider(canvasContext);
        this.nodeRenderer = new nodeRenderer_1.NodeRenderer(this.contextProvider, this.theme);
        this.linkRenderer = new linkRenderer_1.LinkRenderer(this.contextProvider, this.theme);
        this.eventBus.subscribe('RenderRequested', this.renderStart);
        this.cssMiniEngine.compile();
    }
    Renderer.prototype.setCursor = function (cursor) {
        this.contextProvider.context.canvas.style.cursor = cursor;
    };
    Renderer.prototype.renderCursor = function () {
        this.setCursor('grab');
    };
    Renderer.prototype.renderNodes = function () {
        var region = this.stateManager.isScreenShotInProgress()
            ? this.getAllNodesArea()
            : this.getActiveArea();
        var state = this.stateManager.getState();
        var nodes = state.trees.node
            .queryRange(region)
            .concat(state.selectedNodes);
        for (var _i = 0, nodes_1 = nodes; _i < nodes_1.length; _i++) {
            var n = nodes_1[_i];
            var isSelected = state.selectedNodes.indexOf(n) !== -1;
            var isHovered = state.hover.node === n;
            var typeIsHovered = isHovered && state.hover.type;
            this.nodeRenderer.render({
                node: n,
                isSelected: isSelected,
                isHovered: isHovered,
                typeIsHovered: typeIsHovered,
            });
        }
    };
    Renderer.prototype.renderLinks = function () {
        var _this = this;
        var state = this.stateManager.getState();
        var region = this.stateManager.isScreenShotInProgress()
            ? this.getAllNodesArea()
            : this.getActiveArea();
        var linksInArea = state.trees.link.queryRange(region);
        linksInArea.forEach(function (l) { return _this.linkRenderer.render(l, 'main'); });
        state.links
            .filter(function (l) { return state.selectedNodes.find(function (n) { return n === l.i || n === l.o; }); })
            .forEach(function (l) { return _this.linkRenderer.render(l, 'active'); });
    };
    Renderer.prototype.renderBackground = function () {
        if (this.stateManager.isScreenShotInProgress() &&
            !configuration_1.ConfigurationManager.instance.getOption('screenShotBackground')) {
            return;
        }
        var _a = this.contextProvider.context.canvas, width = _a.width, height = _a.height;
        this.contextProvider.context.fillStyle = this.theme.colors.background;
        this.contextProvider.context.fillRect(0, 0, width, height);
    };
    Renderer.prototype.renderMinimap = function () {
        this.minimapRenderer.render(this.contextProvider.context, this.theme, this.stateManager.getState());
    };
    Renderer.prototype.setScreenTransform = function () {
        this.zoomPan.setUniformMatrix(this.contextProvider.context);
    };
    Renderer.prototype.setWorldTransform = function () {
        var uiState = this.stateManager.pureState().uiState;
        this.zoomPan.setCalculatedMatrix(this.contextProvider.context, uiState);
    };
    Renderer.prototype.createScreenShotContext = function (startX, endX, startY, endY) {
        var screenShotCanvas = document.createElement('canvas');
        screenShotCanvas.width = Math.abs(endX - startX);
        screenShotCanvas.height = Math.abs(endY - startY);
        this.screenShotCanvasContext = screenShotCanvas.getContext('2d');
        if (this.screenShotCanvasContext) {
            this.contextProvider.switchContext(this.screenShotCanvasContext);
            this.stateManager.getState().uiState.panX = -startX;
            this.stateManager.getState().uiState.panY = -startY;
            this.stateManager.getState().uiState.scale = 1.0;
        }
    };
    Renderer.prototype.releaseScreenShotContext = function () {
        this.screenShotCanvasContext = null;
        this.contextProvider.switchContext(this.canvasContext);
    };
    return Renderer;
}());
exports.Renderer = Renderer;
