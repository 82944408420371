"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectionManager = void 0;
var utils_1 = require("../../../utils");
var quadTree_1 = require("../../../quadTree");
var ConnectionManager = (function () {
    function ConnectionManager(state, theme) {
        var _this = this;
        this.state = state;
        this.theme = theme;
        this.loadLinks = function (links) {
            _this.state.links = links;
            _this.rebuildTree();
        };
        this.rebuildTree = function () {
            _this.state.trees.link = new quadTree_1.QuadTree();
            _this.state.links
                .filter(function (l) { return !(l.i.hidden || l.o.hidden); })
                .forEach(function (l) {
                return _this.state.trees.link.insert(utils_1.LinkUtils.linkToTree(l, _this.theme));
            });
        };
        this.linkToTree = function (l) {
            return utils_1.LinkUtils.linkToTree(l, _this.theme);
        };
    }
    return ConnectionManager;
}());
exports.ConnectionManager = ConnectionManager;
