"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigurationManager = void 0;
var uuid_1 = require("uuid");
var DefaultDiagramTheme_1 = require("../theme/DefaultDiagramTheme");
var _instance;
var defaultOptions = {
    autosizeWatcher: true,
    autosizeInterval: 1000,
    autosizeOnWindowResize: true,
    generateIdFn: uuid_1.v4,
    height: undefined,
    theme: DefaultDiagramTheme_1.DefaultDiagramTheme,
    width: undefined,
    autoPanSmoothing: 4.0,
    screenShotMargin: 300,
    screenShotBackground: false,
};
var ConfigurationManager = (function () {
    function ConfigurationManager(providedOptions) {
        this.options = defaultOptions;
        _instance = this;
        if (providedOptions.width && providedOptions.height) {
            this.options.autosizeWatcher = false;
            this.options.autosizeOnWindowResize = false;
        }
        this.options = __assign(__assign({}, this.options), providedOptions);
    }
    Object.defineProperty(ConfigurationManager, "instance", {
        get: function () {
            return _instance;
        },
        enumerable: false,
        configurable: true
    });
    ConfigurationManager.prototype.getOption = function (fieldName) {
        return this.options[fieldName];
    };
    ConfigurationManager.prototype.setOption = function (fieldName, value) {
        return (this.options[fieldName] = value);
    };
    return ConfigurationManager;
}());
exports.ConfigurationManager = ConfigurationManager;
